$(document).ready(function () { 
    $(".btn-read-more").click(function () {
        if ($(this).parent().prev().find('.content-member-bios').hasClass("member-bios-more")) {
            $(this).parent().prev().find('.content-member-bios').removeClass("member-bios-more")
                .addClass("member-bios-less");
        }
        else {
            $(this).parent().prev().find('.content-member-bios').removeClass("member-bios-less")
                .addClass("member-bios-more");
        }
    });
});