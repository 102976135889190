function adjustWidgetCarousels() {
    function showHideFlickityCarouselArrows(slides, slideCount) {
        for (var i = 0; i < slides.length; i++) {
            var widget = $(slides[i]);
            var cards = widget.find(".card");
            if (cards && cards.length <= slideCount) {
                widget.find(".flickity-button").hide();
                widget.find(".head-nav-buttons").css({ marginRight: "0" });
            } else {
                widget.find(".flickity-button").show();
                widget.find(".head-nav-buttons").removeAttr("style");
            }
        }
    }

    function adjustWidgetStories() {
        var widgetStories = $(".widget-stories");
        if (widgetStories && widgetStories.length > 0) {
            if ($(window).width() < 576) {
                showHideFlickityCarouselArrows(widgetStories, 1);
            }
            else if ($(window).width() >= 768 && $(window).width() <= 992) {
                showHideFlickityCarouselArrows(widgetStories, 2);
            } else {
                showHideFlickityCarouselArrows(widgetStories, 4);
            }
        }
    }

    function adjustUpcomingEvents() {
        var widgetStories = $(".widget-upcoming-events");
        if (widgetStories && widgetStories.length > 0) {
            if ($(window).width() < 768) {
                showHideFlickityCarouselArrows(widgetStories, 1);
            }
            else {
                showHideFlickityCarouselArrows(widgetStories, 3);
            }
        }
    }

    adjustWidgetStories();
    adjustUpcomingEvents();

    $(window).resize(function () {
        adjustWidgetStories();
        adjustUpcomingEvents();
    });
}

$(document).ready(function () { 
    $(".btn-read-more-2").click(function () {
        const readMoreSectionPrev = $(this).parent().prev();
        if (readMoreSectionPrev.hasClass("read-more-section")) {
            if (readMoreSectionPrev.hasClass("show")) {
                readMoreSectionPrev.removeClass("show");
            } else {
                readMoreSectionPrev.addClass("show");
            }
        }

        const readMoreSectionNext = $(this).parent().next();
        if (readMoreSectionNext.hasClass("read-more-section")) {
            if (readMoreSectionNext.hasClass("show")) {
                readMoreSectionNext.removeClass("show");
            } else {
                readMoreSectionNext.addClass("show");
            }
        }
    });

    var searchInput = document.getElementById("searchInput");
    var searchButton = document.getElementById("searchButton");

    var gotoSearchPage = function (url, keywords) {
        if (!(keywords || "").length) return;

        var fullUrl = url + "#q=" + encodeURIComponent(keywords);
        
        window.location.href = fullUrl;

        if (url == window.location.pathname) {
            window.location.reload();
        }
    };

    var inputSearchKeyUpEvent = function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            var keywords = jQuery(this).val();

            var url = $("#searchInput").data("url");

            gotoSearchPage(url, keywords);
        }
    };

    var searchButtonClickEvent = function (event) {
        // Cancel the default action, if needed
        event.preventDefault();

        var keywords = $("#searchInput").val();
        var url = $("#searchInput").data("url");
        gotoSearchPage(url, keywords);

    };

    searchInput.addEventListener("keydown", inputSearchKeyUpEvent);
    searchButton.addEventListener("click", searchButtonClickEvent);

    if (!$(".carousel-inner div:first-child").hasClass("active")) {
        $(".carousel-inner div:first-child").addClass("active");
    }

    if ($(".carousel-inner").children().length > 1) {
        $(".carousel-inner").next().removeClass("d-none");
        $(".carousel-inner").next().next().removeClass("d-none");
    }

    adjustWidgetCarousels();

    $(".widget-text .description img").each(function (i, x) {
        if (!$(this).hasClass("img-fluid")) {
            $(this).addClass("img-fluid");
        }
    });

    $(".widget-text-media .text-media-container .text-column .description img").each(function (i, x) {
        if (!$(this).hasClass("img-fluid")) {
            $(this).addClass("img-fluid");
        }
    }); 

    var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    $(".side-nav-title").attr("aria-expanded", (windowWidth >= 992).toString());
});


