window.accordionInit = function(widgetID) {

    var $accordionBodyWrapper = $("#accordion_" + widgetID);

    var cards = $accordionBodyWrapper.children(".card");

    cards.each(function (index, card) {
        var $card = $(card);

        var $collapse = $card.find(".collapse").first();
        var contentID = "#accordion_" + widgetID;
        $collapse.attr("data-parent", contentID);
    });
}