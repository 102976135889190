window.tabsInit = function (widgetId) {

    const $tabsBodyWrapper = window.$(`#content-${widgetId}`);

    const tabBodies = $tabsBodyWrapper.children(".tab-pane");

    var $tabsNavUl = window.$(`#tabs-${widgetId}`);

    tabBodies.each(function (index, tab) {
        const regex = /[^a-zA-Z0-9_.]+/ig;
        
        const $tab = window.$(tab);
        const tabTitle = $tab.data("tab-title").replaceAll(regex, "").toLowerCase();

        const newTabLi = window.$("<li/>", { "class": "nav-item", "role": "listitem" });
        const newTabA = window.$("<a/>", {
            "id": "tabs-widget-" + $tab.data("tab-id") + "-item-nav", 
            "class": "nav-link",
            "href": "#tabs-widget-" + $tab.data("tab-id") + "-pane",
            "data-toggle": "tab", 
            "data-tab-title": "#"+tabTitle
        }).text($tab.data("title"));

        newTabLi.append(newTabA);
        $tabsNavUl.append(newTabLi);

        const $collapse = $tab.find(".collapse").first();
        const contentId = "#content-" + widgetId;
        $collapse.attr("data-parent", contentId);
    });

    const tabsNavAnchors = $tabsNavUl.find('a');
    let $firstNavAnchor = tabsNavAnchors.first();

    const hrefAnchor = window.location.hash;
    if (hrefAnchor && tabsNavAnchors && tabsNavAnchors.length > 0) {
        for (var i = 0; i < tabsNavAnchors.length; i++) {
            var currentTab = $(tabsNavAnchors[i]);

            if (currentTab.attr("data-tab-title") === hrefAnchor) {
                $firstNavAnchor = currentTab;
                break;
            }
        }
    }

    $firstNavAnchor.addClass("active");
    $firstNavAnchor.attr("aria-selected", "true");

    let $firstTab = tabBodies.first();

    if (hrefAnchor && tabBodies && tabBodies.length > 0) {
        for (var j = 0; j < tabBodies.length; j++) {
            const currentContent = $(tabBodies[j]);
            if (currentContent.attr("data-tab-title") === hrefAnchor.replace("#","")) {
                $firstTab = currentContent;
                break;
            }
        }
    }

    $firstTab.addClass("show active");
    $firstTab.find("a").first().attr("aria-expanded", "true");

    const $firstTabCardCollapse = $firstTab.find(".collapse").first();
    $firstTabCardCollapse.addClass("show");

    let tabTriggers = document.querySelectorAll('[data-toggleTab]');
    if (tabTriggers) {
        for (var i = 0; i < tabTriggers.length; i++) {
            tabTriggers[i].addEventListener("click", function (e) {
                var scrollTo = this.getAttribute('data-scrollTo');

                console.log('scrollTo', scrollTo);

                var tabToOpenName = this.getAttribute('data-toggleTab');
                let tab = $(this).parents('.section-tabs').find('a[data-tab-title=\'#' + tabToOpenName + '\']');
                if (tab && tab.length > 0) {
                    tab[0].click();
                    let scrollToTarget = $('#' + scrollTo);

                    console.log('scrollTo', scrollToTarget);

                    if (scrollToTarget) {
                        if (scrollToTarget && scrollToTarget.length > 0) {
                            window.setTimeout(() => {
                                scrollToTarget[0].scrollIntoView();
                            }, 250);
                        }
                    }
                }
            });
        }
    }
}